<template>
  <v-card color="#273142" flat>
    <v-card-title color="#dbd7d779">
      <h3 class="mx-auto">Forgot Password</h3>
    </v-card-title>
    <v-divider class="mt-n1"></v-divider>
    <div class="" v-if="!tokenStatus" >
      <v-card-text>
        <v-alert
        dismissible
        :type="actionClass"
        v-if="message"
        class="mt-5"
        >
          {{ message }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="pa-5 text-decoration-underline" @click="$router.push('/auth/forgot-password')"> Resend New link ? </v-btn>
      </v-card-actions>
    </div>
    <v-form v-else ref="form" v-model="valid" lazy-validation @submit.prevent="submitPassword">
      <v-card-text>
        <v-alert
        dismissible
        :type="actionClass"
        v-if="message"
        >
          {{ message }}
        </v-alert>
        <v-text-field
          v-model="newPassword"
          label="Enter password"
          required
          prepend-icon="mdi-email"
          :rules="required"
        ></v-text-field>


        <v-text-field
          v-model="confirmPassword"
          label="Confirm password"
          required
          :rules="[confirmPasswordRule]"
          prepend-icon="mdi-email"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="text-center mb-5">
        <v-spacer> </v-spacer>
        <v-btn type="submit" :disabled="!valid" color="primary" class="mb-5 pa-5" :loading="loading" >
          Submit
        </v-btn>
        <v-spacer> </v-spacer>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import validationMxn from "@/mixins/validation_mixin";
import colorMxn from '@/mixins/colorMxn';

export default {
  name: "ResetPassword",
  mixins: [validationMxn, colorMxn],
  data() {
    return {
      show: true,
      valid: false,
      loading: false,
      newPassword: '',
      confirmPassword: '',
      actionClass: '',
      message: '',
      tokenStatus: false,
    };
  },
  computed: {
    confirmPasswordRule() {
      return () => (this.newPassword === this.confirmPassword) || 'Password must match'
    }
  },
  mounted() {
    this.checkStatus();
  },
  methods: {
    ...mapActions['performPutActions', 'performGetActions'],

    async submitPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const payload = {
        newPassword: this.newPassword,
      };

      const fullPayload = {
        params: payload,
        endpoint: `/users/forgot-password/${this.$route.params.token}/${this.$route.params.email}`,
      };

      const response = await this.performPutActions(fullPayload);
      this.loading = false;
      this.message = response.message;
      this.actionClass = response.status ? 'success' : 'error';

      if (response.status) {

        setTimeout(() => {
          this.$router.push('/auth/login');
        }, 1000)
      }

    },

    async checkStatus() {
      const payload = {
        newPassword: this.newPassword,
      };

      const fullPayload = {
        params: payload,
        endpoint: `/token-status/${this.$route.params.token}/${this.$route.params.email}`,
      };

      const response = await this.performGetActions(fullPayload);
      this.tokenStatus = response.status;
      this.message = response.message;
      this.actionClass = response.status ? '' : 'warning';
    }
  }
};
</script>

<style scoped>
.ant {
  background: #273142;
}
.card-header {
  height: 70px;
  border-bottom: 2px solid #dbd7d779;
}
</style>
